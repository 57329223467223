import React from "react";
import './landing.css';
import tickmark from '../../assets/tick-check-mark-accept-icon-1.svg'
import Quotation from '../quotation/quotation';

function Landing() {
	return (
		<div id='landing' className="scroll-margin-top-150">
			<div className="landing-main" >
				<div class="container">
					<div class="row pt-5 pb-5">
						<div class="col-sm-5 col-xs-12 p-3 landing-text-content" >
							<div className="landing-title text-success mb-3">
							Nigeria's #1 Solar Power Solution
							</div>
							<div className="landing-text-color mb-3" style={{ fontWeight: 500, fontSize: '1.8rem', fontStyle: 'normal' }}>
							Be one of the thousands of Nigerians taking control of their energy future.
							</div>
							<div className="landing-text-color mb-3" style={{ fontWeight: 400, fontSize: '1.5rem', fontStyle: 'normal' }}>
							Tired of sky-high electricity bills? Switch with SolarEase and start saving big while powering your home, business or community cleanly and efficiently.
							</div>
							<div className="my-3 landing-text-color" style={{ lineHeight: '30px' }}>
								<div>
									<img src={tickmark} alt="tick" />&nbsp;&nbsp;
									<span className="landing-text-color" style={{ fontWeight: 500, fontSize: '18px', fontStyle: 'normal' }}>
									Average savings of N1,000,000 per year
									</span>
								</div>
								<div>
									<img src={tickmark} alt="tick" />&nbsp;&nbsp;
									<span style={{ fontWeight: 500, fontSize: '18px', fontStyle: 'normal' }}>
										20-Year Performance Guarantee On Panels
									</span>
								</div>
								<div>
									<img src={tickmark} alt="tick" />&nbsp;&nbsp;
									<span style={{ fontWeight: 500, fontSize: '18px', fontStyle: 'normal' }}>
										Accredited Installers
									</span>
								</div>
								<div>
									<img src={tickmark} alt="tick" />&nbsp;&nbsp;
									<span style={{ fontWeight: 500, fontSize: '18px', fontStyle: 'normal' }}>
										1-Year Workmanship Guarantee
									</span>
								</div>
								<div>
									<img src={tickmark} alt="tick" />&nbsp;&nbsp;
									<span style={{ fontWeight: 500, fontSize: '18px', fontStyle: 'normal' }}>
										Excellent Five-Star Reviews
									</span>
								</div>
							</div>
						</div>
						<div className="col-sm-3 col-xs-12"></div>
						<div class="col-sm-4 col-xs-12 pt-3 pt-md-0">
							<Quotation />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Landing;
