import React from 'react'
import aboutUs from '../../assets/about-us.jpg';
function About() {
	return (
		<div className='container scroll-margin-top-150' id='aboutus'>
			<div className='row my-5'>
				<div className='col-sm-6 col-xs-12 text-end'>
					<img src={aboutUs} alt="" style={{ width: '100%', margin: 'auto' }} />
				</div>
				<div className='col-sm-6 col-xs-12 p-4 pt-0'>
					<p style={{ lineHeight: '29px', fontWeight: 400, fontSize: '1.3rem', color: 'rgb(68, 68, 68)', fontStyle: 'normal' }}>
						At SolarEase,  we're a passionate team on a mission: making clean, affordable solar energy a reality for everyone in Nigeria.
						Founded in 2024 by some passionate Nigerians, we understand the unique needs of Nigerian households and businesses.
						<br />
						<br />We believe everyone deserves access to reliable, sustainable energy, and that's why we offer easy and affordable marketplace for solar solutions.
						Whether you're a homeowner, business owner, or part of the Nigerian diaspora looking to invest back home, SolarEaseHub makes switching to solar a breeze.
						<br /><br />Join our growing community! Thousands of Nigerians are already enjoying the benefits of solar power with SolarEaseHub.
						Become part of a sustainable future and power your home or business with sunshine God has blessed us with!</p>
				</div>
			</div>
		</div>
	)
}

export default About