import React from "react";
function Header() {
  const handleToggle = () => {
    document.getElementById("navbarTogglerDemo01").classList.toggle("show");
  }
 
  return (
    <nav className="navbar navbar-expand-lg sticky-top bg-dark">
      <div className="container-fluid px-sm-5">
        <a className="navbar-brand" href="#">
          <img src="logo.png" alt="Logo" style={{ width: '200px' }} className="d-inline-block align-text-top" />
        </a>
        <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo01" onClick={handleToggle}>
          <ul className="navbar-nav  col-12 col-lg-auto my-2 justify-content-center my-md-0 ">
            <li className="nav-item"><a href="#landing" className="nav-link px-2 text-white " >Why Us</a></li>
            <li className="nav-item"><a href="#reviews" className="nav-link px-2 text-white" >Reviews</a></li>
            <li className="nav-item"><a href="#howItWorks" className="nav-link px-2 text-white" >How it Works</a></li>
            <li className="nav-item"><a href="#refer" className="nav-link px-2 text-white" >Be An Agent</a></li>
            <li className="nav-item"><a href="#aboutus" className="nav-link px-2 text-white" >About Us</a></li>
            <li className="nav-item"><button type="button" class="btn nav-link px-2 text-white" data-bs-toggle="modal" data-bs-target="#contactus" >Contact Us</button></li>
          </ul>

          <div className="d-flex m-3">
            <a href="https://wa.link/gn6g1t" target="_blank" className="contact-btn" style={{ fontSize: '1.5rem' }}>
              <i className="bi bi-whatsapp p-2 2x"></i>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
