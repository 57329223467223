import React from 'react'
import chatIcon from '../../assets/chat-icon-facebook.584abf.svg';
import facebook from '../../assets/facebook.png';
import twitter from '../../assets/twitter.png';
import instagram from '../../assets/instagram.png';

function Footer() {
	return (
		<div className='bg-black p-5'>
			<div className='row text-white'>
				<div className='col-sm-4 col-xs-12' >
					<img src="logo.png" style={{ width: '200px' }} alt="logo" />
					<div style={{ boxSizing: 'border-box' }}>
						<p style={{ marginBottom: '16px', marginTop: '0px', boxSizing: 'border-box' }}>Visit our Social Media page</p>
						<div style={{ display: 'flex', marginBottom: '42px', boxSizing: 'border-box' }}>
							<a href="https://www.facebook.com/people/Solareasehub5/61567808252230/" target="_blank" className='mr-3' style={{ color: 'rgb(255, 255, 255)', textDecoration: 'none solid rgb(255, 255, 255)', boxSizing: 'border-box' }}>
								<img src={facebook} alt="facebook icon" loading="lazy" style={{ verticalAlign: 'middle', boxSizing: 'border-box' }} />
							</a>
							<a href="https://x.com/" target="_blank" className='mx-3' style={{ color: 'rgb(255, 255, 255)', textDecoration: 'none solid rgb(255, 255, 255)', boxSizing: 'border-box' }}>
								<img src={twitter} alt="X icon" loading="lazy" style={{ verticalAlign: 'middle', boxSizing: 'border-box' }} />
							</a>
							<a href="https://www.instagram.com/solareasehub5/" target="_blank" className='mx-2' style={{ color: 'rgb(255, 255, 255)', textDecoration: 'none solid rgb(255, 255, 255)', boxSizing: 'border-box' }}>
								<img src={instagram} alt="Instagram icon" loading="lazy" style={{ color: 'rgb(255, 255, 255)', textDecoration: 'none solid rgb(255, 255, 255)', boxSizing: 'border-box' }} />
							</a>
							<a href="https://wa.link/gn6g1t" target="_blank" style={{ fontSize: '2.1rem', marginTop: '-15px', color: 'white' }}>
								<i className="bi bi-whatsapp p-2 2x" alt="X icon" loading="lazy" style={{ verticalAlign: 'middle', boxSizing: 'border-box' }}></i>
							</a>
						</div>
					</div>
				</div>

				<div className='col-sm-2 col-xs-6'>
					<div style={{ border: '0px none rgb(255, 255, 255)', cursor: 'default', flex: '0 0 auto', borderTop: '0px none rgb(255, 255, 255)', padding: '20px 0px', position: 'relative', flexShrink: 0, marginTop: '0px', maxWidth: '100%', paddingLeft: '0px', paddingRight: '0px', boxSizing: 'border-box' }}>
						<h2 style={{ fontSize: '16px', color: 'rgb(255, 255, 255)', fontWeight: 500, margin: '0px', textDecoration: 'none solid rgb(255, 255, 255)', marginBottom: '0px', lineHeight: '19.2px', marginTop: '0px', boxSizing: 'border-box' }}>About</h2>
						<div style={{ animationDuration: '1s', animationName: 'open', marginTop: '24px', maxHeight: '500px', overflow: 'hidden', boxSizing: 'border-box' }}>
							<ul style={{ listStyle: 'outside none none', marginBottom: '0px', paddingLeft: '0px', marginTop: '0px', boxSizing: 'border-box' }}>
								<li style={{ padding: '8px 0px', boxSizing: 'border-box' }}><a href="#aboutus" style={{ color: 'rgb(255, 255, 255)', textDecoration: 'none solid rgb(255, 255, 255)', boxSizing: 'border-box' }}>About SolarEase</a></li>
								<li style={{ padding: '8px 0px', boxSizing: 'border-box' }}><a href="#refer" aria-current="page" style={{ color: 'rgb(255, 255, 255)', textDecoration: 'none solid rgb(255, 255, 255)', boxSizing: 'border-box' }}>Refer</a></li>
								<li style={{ padding: '8px 0px', boxSizing: 'border-box' }}><a href="#" style={{ color: 'rgb(255, 255, 255)', textDecoration: 'none solid rgb(255, 255, 255)', boxSizing: 'border-box' }} data-bs-toggle="modal" data-bs-target="#contactus">Apply For Installer</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div className='col-sm-4 col-xs-6'>
					<div style={{ border: '0px none rgb(255, 255, 255)', cursor: 'default', flex: '0 0 auto', borderTop: '0px none rgb(255, 255, 255)', padding: '20px 0px', position: 'relative', flexShrink: 0, marginTop: '0px', maxWidth: '100%', paddingLeft: '0px', paddingRight: '0px', boxSizing: 'border-box' }}>
						<h2 style={{ fontSize: '16px', color: 'rgb(255, 255, 255)', fontWeight: 500, margin: '0px', textDecoration: 'none solid rgb(255, 255, 255)', marginBottom: '0px', lineHeight: '19.2px', marginTop: '0px', boxSizing: 'border-box' }}>Contact</h2>
						<div style={{ animationDuration: '1s', animationName: 'open', marginTop: '24px', maxHeight: '500px', overflow: 'hidden', boxSizing: 'border-box' }}>
							<ul style={{ listStyle: 'outside none none', marginBottom: '0px', paddingLeft: '0px', marginTop: '0px', boxSizing: 'border-box' }}>
								<li style={{ boxSizing: 'border-box', color: 'rgb(255, 255, 255)' }}>
									BEULAH TRANSFORMATION PARTNERS LIMITED</li>
								<li style={{ boxSizing: 'border-box', color: 'rgb(255, 255, 255)' }}>
									Registration Number: 1659632</li>
								<li style={{ boxSizing: 'border-box', color: 'rgb(255, 255, 255)' }}>
									Address: ALMA 1 UNIT 8 RICHMOND GATE ESTATE OFF MEADOW HALL WAY IKATE ELEGUSHI </li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}

export default Footer