export const SOLAR_OPTS = [
    { label: 'Solar', value: 'solar' },
    { label: 'Solar + Battery Storage', value: 'solarBattery' },
    { label: 'Just Battery Storage', value: 'battery' },
    { label: 'Solar Security Systems', value: 'solarSecuritySystems' },
    { label: 'Solar Water Pump', value: 'solarWaterPump' },
    { label: 'Solar Street Lighting', value: 'solarStreetLighting' }
];
export const SOLAR_REQUEST_TYPE_OPTS = [
    { label: 'New', value: 'new' },
    { label: 'Service', value: 'service' },
    { label: 'Replacement', value: 'replacement' }
];

export const SOLAR_INSTALL_PERIOD_OPTS = [
    { label: 'ASAP', value: 'asap' },
    { label: '1 - 2 Months', value: 'two-months' }
];

export const CAPACITY_OPTS = [
    { label: '1 KVA', value: '1KVA' },
    { label: '2 KVA', value: '2KVA' },
    { label: '2.5 KVA', value: '2.5KVA' },
    { label: '3.5 KVA', value: '3.5KVA' },
    { label: '4 KVA', value: '4KVA' },
    { label: '5 KVA', value: '5KVA' },
    { label: '7.5 KVA', value: '7.5KVA' },
    { label: '10 KVA', value: '10KVA' },
    { label: '15 KVA', value: '15KVA' },
    { label: 'Not Sure', value: 'not sure' }
];
export const UNSURE_CAPACITY = [
    { label: '1 Bed Apartment', value: '1 Bed Apartment' },
    { label: '2 Bed Apartment', value: '2 Bed Apartment' },
    { label: '3 Bed Apartment', value: '3 Bed Apartment' },
    { label: '3 Bed Duplex', value: '3 Bed Duplex' },
    { label: '4 Bed Flat/Duplex', value: '4 Bed Flat/Duplex' },
    { label: '5 Bed House/Duplex', value: '5 Bed House/Duplex' },
    { label: 'Non Residential', value: 'Non Residential' }
];

export const MAJOR_APPLIANCES_OPTS = [
    { label: '1 Fridge', value: 'fridge' },
    { label: '1 Freezer', value: 'freezer' },
    { label: '1 Aircon', value: 'aircon' },
    { label: '2 - 4 Aircon', value: 'multiple-aircon' },
    { label: '2 - 4 Freezer', value: 'multiple-freezer' },
    { label: 'Unsure', value: 'Unsure' },
];

export const PROPERTY_ROOF_TYPE_OPTS = [
    { label: 'Pitched', value: 'pitched' },
    { label: 'Mono Pitched', value: 'mono-pitched' },
    { label: 'Flat', value: 'flat' },
    { label: 'Car Port', value: 'car-port' },
    { label: 'Other', value: 'other' },
    { label: 'Unsure', value: 'unsure' }
];

export const PROPERTY_TYPE_OPTS = [
    { label: 'Detached', value: 'detached' },
    { label: 'Semi-Detached', value: 'semi-detached' },
    { label: 'Terraced', value: 'terraced' },
    { label: 'Bungalow', value: 'bungalow' },
    { label: 'Flat', value: 'flat' },
    { label: 'Other', value: 'other' },
];
export const PROPERTY_STATUS_OPTS = [
    { label: 'Yes', value: 'yes' },
    { label: `I'm looking on behalf of the homeowner`, value: 'behalf-of-owner' },
    { label: `I'm in the process of buying`, value: 'buying' },
    { label: `I'm renting this property`, value: 'rented' },
    { label: 'Other', value: 'other' }
];

export const PROPERTY_OPTS = [
    { label: 'Residential', value: 'residential' },
    { label: 'Commercial', value: 'commercial' }
];

export const REQUESTING_FOR_YOURSELF = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' }
]

export const NIGERIAN_STATES = [
    {
        name: "Abia",
        LGAs: [
            "Aba North",
            "Aba South",
            "Arochukwu",
            "Bende",
            "Ikwuano",
            "Isiala Ngwa North",
            "Isiala Ngwa South",
            "Isuikwuato",
            "Obingwa",
            "Ohafia",
            "Osisioma Ngwa",
            "Ugwunagbo",
            "Ukwa East",
            "Ukwa West",
            "Umuahia North",
            "Umuahia South"
        ]
    },
    {
        name: "Adamawa",
        LGAs: [
            "Demsa",
            "Fufore",
            "Ganye",
            "Girei",
            "Guyuk",
            "Hong",
            "Jada",
            "Lamurde",
            "Madagali",
            "Maiha",
            "Mayo-Belwa",
            "Michika",
            "Numan",
            "Shelleng",
            "Song",
            "Toungo",
            "Yola North",
            "Yola South"
        ]
    },
    {
        name: "Akwa Ibom",
        LGAs: [
            "Abak",
            "Eket",
            "Esit Eket",
            "Etim Ekpo",
            "Etinan",
            "Ibiono Ibom",
            "Ibesikpo Asutan",
            "Ikono",
            "Ikot Abasi",
            "Ikot Ekpene",
            "Obot Akara",
            "Onna",
            "Oron",
            "Udung Uko",
            "Ukanafun",
            "Uruan",
            "Urue-Offong/Oruko",
            "Uyo"
        ]
    },
    {
        name: "Anambra",
        LGAs: [
            "Aguata",
            "Awka North",
            "Awka South",
            "Anaocha",
            "Dunukofia",
            "Ekwusigo",
            "Idemili North",
            "Idemili South",
            "Ihiala",
            "Njikoka",
            "Nnewi North",
            "Nnewi South",
            "Ogbaru",
            "Onitsha North",
            "Onitsha South",
            "Orumba North",
            "Orumba South",
            "Oyi"
        ]
    },
    {
        name: "Bauchi",
        LGAs: [
            "Alkaleri",
            "Bauchi",
            "Bogoro",
            "Damban",
            "Darazo",
            "Dass",
            "Misau",
            "Ningi",
            "Shira",
            "Tafawa Balewa",
            "Toro",
            "Warji",
            "Zaki"
        ]
    },
    {
        name: "Bayelsa",
        LGAs: [
            "Brass",
            "Ekeremor",
            "Kolokuma/Opokuma",
            "Nembe",
            "Ogbia",
            "Sagbama",
            "Southern Ijaw",
            "Yenagoa"
        ]
    },
    {
        name: "Benue",
        LGAs: [
            "Ado",
            "Agatu",
            "Apa",
            "Buruku",
            "Gboko",
            "Guma",
            "Gwer East",
            "Gwer West",
            "Katsina-Ala",
            "Konshisha",
            "Logo",
            "Makurdi",
            "Obi",
            "Ogbadibo",
            "Ohimini",
            "Oju",
            "Okpokwu",
            "Otukpo",
            "Tarka",
            "Vandeikya"
        ]
    },
    {
        name: "Borno",
        LGAs: [
            "Abadam",
            "Askira/Uba",
            "Bama",
            "Bayo",
            "Chibok",
            "Damboa",
            "Dikwa",
            "Gubio",
            "Gwoza",
            "Kaga",
            "Kala/Balge",
            "Konduga",
            "Mafa",
            "Magumeri",
            "Maiduguri",
            "Marte",
            "Mobbar",
            "Ngala",
            "Nganzai",
            "Shani"
        ]
    },
    {
        name: "Cross River",
        LGAs: [
            "Akpabuyo",
            "Bakassi",
            "Biase",
            "Boki",
            "Calabar Municipal",
            "Calabar South",
            "Ekureku",
            "Ikom",
            "Obubra",
            "Obudu",
            "Odukpani",
            "Okagwe",
            "Ogoja",
            "Yakuur",
            "Yala"
        ]
    },
    {
        name: "Delta",
        LGAs: [
            "Aniocha North",
            "Aniocha South",
            "Asaba",
            "Bomadi",
            "Burutu",
            "Ethiope East",
            "Ethiope West",
            "Ika North East",
            "Ika South",
            "Okpe",
            "Oshimili North",
            "Oshimili South",
            "Patani",
            "Sapele",
            "Udu",
            "Ughelli North",
            "Ughelli South",
            "Warri North",
            "Warri South",
            "Warri South-West"
        ]
    },
    {
        name: "Ebonyi",
        LGAs: [
            "Abakaliki",
            "Afikpo North",
            "Afikpo South",
            "Ebonyi",
            "Ezza North",
            "Ezza South",
            "Ikwo",
            "Ishielu",
            "Ivo",
            "Ohaozara",
            "Ohaukwu",
            "Onicha"
        ]
    },
    {
        name: "Edo",
        LGAs: [
            "Akoko Edo",
            "Esan Central",
            "Esan North-East",
            "Esan South-East",
            "Esan West",
            "Egor",
            "Igueben",
            "Ikpoba Okha",
            "Oredo",
            "Orhionmwon",
            "Ovia North-East",
            "Ovia South-West",
            "Uhunmwonde"
        ]
    },
    {
        name: "Ekiti",
        LGAs: [
            "Ado Ekiti",
            "Efon",
            "Ekiti East",
            "Ekiti South-West",
            "Ekiti West",
            "Emure",
            "Ijero",
            "Ikere",
            "Ilesa",
            "Irepodun/Ifelodun",
            "Ise/Orun",
            "Moba",
            "Oye"
        ]
    },
    {
        name: "Enugu",
        LGAs: [
            "Agwu",
            "Awgu",
            "Enugu East",
            "Enugu North",
            "Enugu South",
            "Ezeagu",
            "Igbo-Etiti",
            "Igboeze North",
            "Igboeze South",
            "Isi Uzo",
            "Nkanu East",
            "Nkanu West",
            "Oji River",
            "Udenu",
            "Udi",
            "Nsukka"
        ]
    },
    {
        name: "Gombe",
        LGAs: [
            "Akko",
            "Balanga",
            "Billiri",
            "Dukku",
            "Funakaye",
            "Gombe",
            "Kaltungo",
            "Kwami",
            "Nafada",
            "Shongom",
            "Yamaltu/Deba"
        ]
    },
    {
        name: "Imo",
        LGAs: [
            "Aboh Mbaise",
            "Ahiazu Mbaise",
            "Ehime Mbano",
            "Ezinihitte",
            "Ikeduru",
            "Isiala Mbano",
            "Isu",
            "Mbaitoli",
            "Ngor Okpala",
            "Njaba",
            "Nkwere",
            "Obowo",
            "Oguta",
            "Ohaji/Egbema",
            "Orlu",
            "Orsu",
            "Owerri Municipal",
            "Owerri North",
            "Owerri West",
            "Oru East",
            "Oru West"
        ]
    },
    {
        name: "Jigawa",
        LGAs: [
            "Auyo",
            "Babura",
            "Birnin Kudu",
            "Buji",
            "Dutse",
            "Gagarawa",
            "Gumel",
            "Hadejia",
            "Kazaure",
            "Kiri Kasama",
            "Maigatari",
            "Malammadori",
            "Miga",
            "Ringim",
            "Roni",
            "Sule Tankarkar",
            "Yankwashi"
        ]
    },
    {
        name: "Kaduna",
        LGAs: [
            "Birnin Gwari",
            "Chikun",
            "Giwa",
            "Igabi",
            "Ikara",
            "Jaba",
            "Jema'a",
            "Kachia",
            "Kaduna North",
            "Kaduna South",
            "Kagarko",
            "Kajuru",
            "Lere",
            "Makarfi",
            "Sabon Gari",
            "Sanga",
            "Soba",
            "Zango Kataf",
            "Zaria"
        ]
    },
    {
        name: "Kano",
        LGAs: [
            "Ajingi",
            "Albasu",
            "Bagwai",
            "Bebeji",
            "Bichi",
            "Bunkure",
            "Dala",
            "Dambatta",
            "Dawakin Kudu",
            "Dawakin Tofa",
            "Gaya",
            "Garko",
            "Garun Mallam",
            "Kabo",
            "Kano Municipal",
            "Karaye",
            "Kiru",
            "Kumbotso",
            "Madobi",
            "Makoda",
            "Minjibir",
            "Nasarawa",
            "Rogo",
            "Shanono",
            "Tarauni",
            "Tofa",
            "Tsanyawa",
            "Warawa",
            "Wudil"
        ]
    },
    {
        name: "Kogi",
        LGAs: [
            "Adavi",
            "Ajaokuta",
            "Bassa",
            "Dekina",
            "Ibaji",
            "Idah",
            "Igalamela Odolu",
            "Ijumu",
            "Kabba/Bunu",
            "Kogi",
            "Lokoja",
            "Mopa-Muro",
            "Ofu",
            "Ogori/Magongo",
            "Okene",
            "Okehi",
            "Okunrun",
            "Olamaboro",
            "Yagba East",
            "Yagba West"
        ]
    },
    {
        name: "Kwara",
        LGAs: [
            "Asa",
            "Baruten",
            "Ekiti",
            "Ifelodun",
            "Ilorin East",
            "Ilorin South",
            "Ilorin West",
            "Irepodun",
            "Isin",
            "Kaiama",
            "Moro",
            "Oyun",
            "Pategi"
        ]
    },
    {
        name: "Lagos",
        LGAs: [
            "Agege",
            "Ajeromi-Ifelodun",
            "Alimosho",
            "Amuwo-Odofin",
            "Apapa",
            "Badagry",
            "Epe",
            "Eti Osa",
            "Ibeju Lekki",
            "Ifako-Ijaye",
            "Ikeja",
            "Ikorodu",
            "Lagos Island",
            "Lagos Mainland",
            "Mushin",
            "Ojo",
            "Oshodi-Isolo",
            "Somolu",
            "Surulere"
        ]
    },
    {
        name: "Nasarawa",
        LGAs: [
            "Akwanga",
            "Alkalu",
            "Doma",
            "Karshi",
            "Keana",
            "Keffi",
            "Kokona",
            "Lafia",
            "Nasarawa",
            "Obi",
            "Toto",
            "Wamba"
        ]
    },
    {
        name: "Niger",
        LGAs: [
            "Agaie",
            "Agwara",
            "Bida",
            "Bokani",
            "Borgu",
            "Chanchaga",
            "Edati",
            "Gbayu",
            "Gurara",
            "Katcha",
            "Kontagora",
            "Lapai",
            "Lavun",
            "Mashegu",
            "Mokwa",
            "Rafi",
            "Rijau",
            "Shiroro",
            "Suleja",
            "Tafa",
            "Wushishi"
        ]
    },
    {
        name: "Ogun",
        LGAs: [
            "Abeokuta North",
            "Abeokuta South",
            "Ado-Odo/Ota",
            "Ewekoro",
            "Ifo",
            "Ijebu East",
            "Ijebu North",
            "Ijebu North East",
            "Ijebu Ode",
            "Ogun Waterside",
            "Remo North",
            "Sagamu",
            "Yewa North",
            "Yewa South"
        ]
    },
    {
        name: "Ondo",
        LGAs: [
            "Akoko North East",
            "Akoko North West",
            "Akoko South East",
            "Akoko South West",
            "Akure North",
            "Akure South",
            "Ese Odo",
            "Idanre",
            "Ifedore",
            "Ilaje",
            "Odigbo",
            "Okitipupa",
            "Ondo East",
            "Ondo West",
            "Owena",
            "Owo"
        ]
    },
    {
        name: "Osun",
        LGAs: [
            "Aiyedade",
            "Aiyedire",
            "Atakunmosa East",
            "Atakunmosa West",
            "Boripe",
            "Ife East",
            "Ife North",
            "Ife South",
            "Ilesa East",
            "Ilesa West",
            "Irewole",
            "Isokan",
            "Obokun",
            "Odo-Otin",
            "Oluyole",
            "Osogbo",
            "Olorunda",
            "Oriade",
            "Iwo"
        ]
    },
    {
        name: "Oyo",
        LGAs: [
            "Akinyele",
            "Atiba",
            "Afijio",
            "Egbeda",
            "Ibarapa Central",
            "Ibarapa East",
            "Ibarapa North",
            "Ido",
            "Iseyin",
            "Itesiwaju",
            "Kajola",
            "Lagos",
            "Ogbomosho North",
            "Ogbomosho South",
            "Oyo East",
            "Oyo West",
            "Saki East",
            "Saki West",
            "Surulere"
        ]
    },
    {
        name: "Plateau",
        LGAs: [
            "Bokkos",
            "Barkin Ladi",
            "Jos East",
            "Jos North",
            "Jos South",
            "Kanam",
            "Langtang North",
            "Langtang South",
            "Mangu",
            "Pankshin",
            "Quanpan",
            "Riyom",
            "Shendam",
            "Wase"
        ]
    },
    {
        name: "Rivers",
        LGAs: [
            "Abua/Odual",
            "Ahoada East",
            "Ahoada West",
            "Akuku-Toru",
            "Andoni",
            "Asari-Toru",
            "Bonny",
            "Degema",
            "Eleme",
            "Emohua",
            "Etche",
            "Gokana",
            "Ikwerre",
            "Obio-Akpor",
            "Ogba/Egbema/Ndoni",
            "Ogu/Bolo",
            "Okrika",
            "Omuma",
            "Port Harcourt",
            "Tai"
        ]
    },
    {
        name: "Sokoto",
        LGAs: [
            "Bodinga",
            "Dange/Shuni",
            "Gada",
            "Goronyo",
            "Gudu",
            "Illela",
            "Kebbe",
            "Kware",
            "Rabah",
            "Sabon Birni",
            "Shagari",
            "Sokoto North",
            "Sokoto South",
            "Tambuwal",
            "Tangaza",
            "Wamakko",
            "Wurno",
            "Yabo"
        ]
    },
    {
        name: "Taraba",
        LGAs: [
            "Abbo",
            "Ardo Kola",
            "Bali",
            "Donga",
            "Gashaka",
            "Gassol",
            "Ibi",
            "Jalingo",
            "Karin Lamido",
            "Kwanpun",
            "Lau",
            "Sardauna",
            "Takum",
            "Ussa",
            "Wukari",
            "Yorro",
            "Zing"
        ]
    },
    {
        name: "Yobe",
        LGAs: [
            "Bade",
            "Bursari",
            "Damaturu",
            "Fika",
            "Fune",
            "Geidam",
            "Gujba",
            "Gulani",
            "Jakusko",
            "Karasuwa",
            "Nguru",
            "Potiskum",
            "Tarmuwa",
            "Yunusari",
            "Yobe East",
            "Yobe West"
        ]
    },
    {
        name: "Zamfara",
        LGAs: [
            "Anka",
            "Bakura",
            "Birnin Magaji",
            "Bukkuyum",
            "Gummi",
            "Gusau",
            "Isa",
            "Kaura Namoda",
            "Maradun",
            "Shinkafi",
            "Talata Mafara",
            "Zamfara West"
        ]
    }
];
