import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import './quotation.css';
import {
    SOLAR_OPTS, CAPACITY_OPTS, MAJOR_APPLIANCES_OPTS, PROPERTY_ROOF_TYPE_OPTS,
    PROPERTY_TYPE_OPTS, PROPERTY_STATUS_OPTS, SOLAR_REQUEST_TYPE_OPTS, SOLAR_INSTALL_PERIOD_OPTS, PROPERTY_OPTS, REQUESTING_FOR_YOURSELF,
    UNSURE_CAPACITY,
    NIGERIAN_STATES
} from "./constants";
import { getQuotation } from "../../services/quotation-service";
import { getTownsAndLgas, getCountries } from "../../services/address-service";
import { Typeahead } from "react-bootstrap-typeahead";

const CustomStepper = styled('div')`
 position: absolute;
    margin-left: ${props => (props.currentStep !== 1 ? `${(100 / props.totalsteps) * (props.currentStep - 1)}%` : `5%`)};
`;

function Quotation() {
    return (
        <>
            <div id="quotation-box" className="quota-box">
                <div className="text-center pt-3">
                    <span className="quota-heading ">
                        Get a Quote 👇
                    </span>
                </div>
                <Stepper />
            </div>
        </>
    )
}


export const Stepper = () => {
    const [active, setActive] = useState(1);
    const [formValues, setFormValues] = useState();

    const handleNextPrevClick = (a) => {
        setActive(a);

    };
    const updateForm = (values) => {
        setFormValues(prevState => {
            return { ...prevState, ...values }
        });
    }
    const resetForm = () => {
        setFormValues({});
        setActive(1);
    }
    const componentProps = {
        handleNextPrevClick,
        active,
        formValues,
        onUpdate: updateForm
    };
    return (
        <>
            <div className="row">
                <div className="col-12"><hr style={{ border: '2px solid green', borderRadius: '2px' }}></hr></div>
                {/* <CustomStepper currentStep={active} totalsteps={9}><h5><span class="badge rounded-pill bg-success">{[1, 2, 3].includes(active) ? active : formValues.capacity ? active : active - 1}</span></h5></CustomStepper> */}
            </div>
            {active === 1 && <Step1 {...componentProps} resetForm={resetForm} />}
            {active === 2 && <Step2 {...componentProps} />}
            {active === 3 && <Step3 {...componentProps} />}
            {active === 4 && <Step4 {...componentProps} />}
            {active === 5 && <Step5 {...componentProps} />}
            {active === 6 && <Step6 {...componentProps} />}
            {active === 7 && <Step7 {...componentProps} />}
            {active === 8 && <Step8 {...componentProps} />}
            {active === 9 && <Step10 {...componentProps} resetForm={resetForm} />}

        </>
    );
};

const Step1 = ({ handleNextPrevClick, active, formValues, onUpdate, resetForm }) => {
    const [
        selectedValue,
        setSelectedValue,
    ] = useState(formValues?.type);
    const [
        selectedRequestTypeValue,
        setSelectedRequestTypeValue,
    ] = useState(formValues?.requestType);

    const handleRadioChange = (e) => {
        const { value } = e.currentTarget;
        setSelectedValue(value);
        onUpdate({ type: value });
    };

    const handleRequestRadioChange = (e) => {
        const { value } = e.currentTarget;
        setSelectedRequestTypeValue(value);
        const type = formValues?.type || selectedValue;
        resetForm(); // reset form values after radio selection
        onUpdate({ requestType: value, type: type });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedRequestTypeValue !== 'New') {
            handleNextPrevClick(6);
        } else {
            handleNextPrevClick(active + 1);
        }

    };

    return (
        <>
            <div className="container p-4 pt-2">
                <form name="form" onSubmit={handleSubmit} >
                    <fieldset>
                        <div className="row pb-3 text-black" >
                            <div className="col-12"><b>What type of Solar System can we help you with?</b></div>
                        </div>
                        <div className="row justify-content-center pb-2">
                            {SOLAR_REQUEST_TYPE_OPTS.map((solarRequestTypeOpts) => {
                                return <div class="col-3 form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id={solarRequestTypeOpts.value} value={solarRequestTypeOpts.label} required
                                        onChange={handleRequestRadioChange} checked={selectedRequestTypeValue === solarRequestTypeOpts.label} />
                                    <label class="form-check-label" for={solarRequestTypeOpts.value}>{solarRequestTypeOpts.label}</label>
                                </div>
                            })}
                        </div>
                        <div className="row">
                            <div className="btn-group-vertical">
                                {SOLAR_OPTS.map(((solarOpts, index) => {
                                    return <div className="col-12 pb-3 d-grid gap-2">
                                        <input type="radio" className="btn-check" name="btnradio" id={`btnradiosolar${index}`} autocomplete="off" value={solarOpts.label}
                                            onChange={handleRadioChange} checked={selectedValue === solarOpts.label} required />
                                        <label className="btn btn-outline-success" for={`btnradiosolar${index}`}>{solarOpts.label}</label>
                                    </div>
                                }))}
                            </div>
                        </div>
                        <div className="row" style={{ position: 'absolute', bottom: '2rem', width: '95%' }}>
                            <div className="col-12">
                                <div class="d-grid gap-2">
                                    <button className="btn btn-success rounded-pill" type="submit" >Continue</button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </>
    );
};

const Step2 = ({ handleNextPrevClick, active, formValues, onUpdate }) => {
    const [
        selectedValue,
        setSelectedValue,
    ] = useState(formValues?.capacity);

    const handleRadioChange = (e) => {
        const { value } = e.currentTarget;
        setSelectedValue(value);
        onUpdate({ capacity: value, apartmentType: null });

    };
    const handleSubmit = (e) => {
        selectedValue ? handleNextPrevClick(active + 2) : handleNextPrevClick(active + 1);
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        onUpdate({ [name]: value });
    }
    return (
        <div className="container p-4 pt-2">
            <div className="row pb-3 text-black">
                <div className="col-12"><b>Do you know exactly the capacity you need?</b></div>
            </div>
            <div className="row">
                {CAPACITY_OPTS.map((capacity, index) => {
                    return <div className="col-6 pb-3 d-grid gap-2">
                        <input type="radio" className="btn-check" name="btnradio" id={`btnradio${index}`} autocomplete="off" value={capacity.label} onChange={handleRadioChange}
                            checked={selectedValue === capacity.label} />
                        <label className="btn btn-outline-success" for={`btnradio${index}`}>{capacity.label}</label>
                    </div>
                })}
            </div>
            {selectedValue === 'Not Sure' &&
                <div className="row pt-1">
                    <div className="col-12 pb-3"><b>Please select an apartment type</b></div>
                    <div class="col-12">
                        <select class="form-control form-select" style={{ border: '1px solid green' }} id="floatingSelect" name='apartmentType' value={formValues.apartmentType} onChange={handleChange} required>
                            {UNSURE_CAPACITY.map((capacity) => <option value={capacity.label}>{capacity.label}</option>)}
                        </select>
                    </div>
                </div>
            }
            <div className="row" style={{ position: 'absolute', bottom: '2rem', width: '95%' }}>
                <div className="col-1">
                    <button class="btn rounded-pill btn-outline-success" type="button" onClick={() => handleNextPrevClick(active - 1)}>Back</button>
                </div>
                <div className="col-1" style={{ position: 'absolute', right: '5.3rem' }}>
                    <button class="btn btn-success rounded-pill " type="submit" onClick={() => handleSubmit()}>Continue</button>
                </div>
            </div>
        </div >
    );
};
const Step3 = ({ handleNextPrevClick, active, formValues, onUpdate }) => {

    const [
        selectedValue,
        setSelectedValue,
    ] = useState(formValues?.majorAppliances);


    const handleRadioChange = (e) => {
        const { value } = e.currentTarget;
        setSelectedValue(value);
        onUpdate({ majorAppliances: value });
    };
    return (
        <div className="container p-4 pt-2">
            <form name="form" onSubmit={() => handleNextPrevClick(active + 1)} >
                <fieldset>
                    <div className="row pb-3 text-black">
                        <div className="col-12"><b>Which option best describes the major appliances you need to manage?
                        </b></div>
                    </div>
                    <div className="row">
                        <div className="btn-group-vertical">
                            {MAJOR_APPLIANCES_OPTS.map((appliances, index) => {
                                return <div className="col-12 pb-3 d-grid gap-2">
                                    <input type="radio" className="btn-check" name="btnradio" id={`appliances${index}`} autocomplete="off" value={appliances.label}
                                        onChange={handleRadioChange} checked={selectedValue === appliances.label} required />
                                    <label class="btn btn-outline-success" for={`appliances${index}`}>{appliances.label}</label>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="row" style={{ position: 'absolute', bottom: '2rem', width: '95%' }}>
                        <div className="col-1">
                            <button class="btn rounded-pill btn-outline-success" type="button" onClick={() => handleNextPrevClick(active - 1)}>Back</button>
                        </div>
                        <div className="col-1" style={{ position: 'absolute', right: '5.3rem' }}>
                            <button class="btn btn-success rounded-pill " type="submit" >Continue</button>
                        </div>
                    </div>
                </fieldset>
            </form>

        </div>
    );
};

const Step4 = ({ handleNextPrevClick, active, formValues, onUpdate }) => {
    const [
        selectedValue,
        setSelectedValue,
    ] = useState(formValues?.roofType);

    const handleRadioChange = (e) => {
        const { value } = e.currentTarget;
        setSelectedValue(value);
        onUpdate({ roofType: value });
    }

    const handlePrevClick = () => {
        formValues.capacity ? handleNextPrevClick(active - 2) : handleNextPrevClick(active - 1);
    }

    return (
        <div className="container p-4 pt-2">
            <form name="form" onSubmit={() => handleNextPrevClick(active + 1)} >
                <fieldset>
                    <div className="row pb-3 text-black">
                        <div className="col-12"><b>What is your property's roof type?</b></div>
                    </div>
                    <div className="row">
                        <div className="btn-group-vertical">
                            {PROPERTY_ROOF_TYPE_OPTS.map((roofType, index) => {
                                return <div className="col-12 pb-3 d-grid gap-2">
                                    <input type="radio" className="btn-check" name="btnradio" id={`roofType${index}`} autocomplete="off" value={roofType.label}
                                        onChange={handleRadioChange} checked={selectedValue === roofType.label} required />
                                    <label class="btn btn-outline-success" for={`roofType${index}`}>{roofType.label}</label>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="row" style={{ position: 'absolute', bottom: '2rem', width: '95%' }}>
                        <div className="col-1">
                            <button class="btn rounded-pill btn-outline-success" type="button" onClick={() => handlePrevClick()}>Back</button>
                        </div>
                        <div className="col-1" style={{ position: 'absolute', right: '5.3rem' }}>
                            <button class="btn btn-success rounded-pill " type="submit" >Continue</button>
                        </div>
                    </div>
                </fieldset>
            </form>

        </div>
    );
};

const Step5 = ({ handleNextPrevClick, active, formValues, onUpdate }) => {
    const [
        selectedValue,
        setSelectedValue,
    ] = useState(formValues?.propertyType);
    const [
        selectedPropType,
        setSelectedPropType,
    ] = useState(formValues?.residentialOrCommercial);

    const handleRadioChange = (e) => {
        const { value } = e.currentTarget;
        setSelectedValue(value);
        onUpdate({ propertyType: value });
    }
    const handlePropTypeChange = (e) => {
        const { value } = e.currentTarget;
        setSelectedPropType(value);
        onUpdate({ residentialOrCommercial: value });
    }

    return (
        <div className="container p-4 pt-2">
            <form name="form" onSubmit={() => handleNextPrevClick(active + 1)}>
                <fieldset>
                    <div className="row pb-3 text-black">
                        <div className="col-12"><b>Property type for the solar installation</b></div>
                    </div>
                    <div className="row px-3">
                        <div className="row pb-2">
                            {PROPERTY_OPTS.map((propType) => {
                                return <div class="col-4 form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id={propType.value} value={propType.label} required
                                        onChange={handlePropTypeChange} checked={selectedPropType === propType.label} />
                                    <label class="form-check-label" for={propType.value}>{propType.label}</label>
                                </div>
                            })}
                        </div>
                    </div>

                    <div className="row">
                        {PROPERTY_TYPE_OPTS.map((propertyType) => {
                            return <div className="col-12 pb-3 d-grid gap-2">
                                <input type="radio" className="btn-check" name="btnradio" id={propertyType.value} autocomplete="off" value={propertyType.label} onChange={handleRadioChange}
                                    checked={selectedValue === propertyType.label} required />
                                <label className="btn btn-outline-success" for={propertyType.value}>{propertyType.label}</label>
                            </div>
                        })}
                    </div>
                    <div className="row" style={{ position: 'absolute', bottom: '2rem', width: '95%' }}>
                        <div className="col-1">
                            <button class="btn rounded-pill btn-outline-success" type="button" onClick={() => handleNextPrevClick(active - 1)}>Back</button>
                        </div>
                        <div className="col-1" style={{ position: 'absolute', right: '5.3rem' }}>
                            <button class="btn btn-success rounded-pill " type="submit" >Continue</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );
};

const Step6 = ({ handleNextPrevClick, active, formValues, onUpdate }) => {
    const states = NIGERIAN_STATES.map(state => state.name);
    const { address, state, street, lga, requestType } = formValues || {};
    const handleSubmit = (e) => {
        e.preventDefault();
        handleNextPrevClick(active + 1);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        onUpdate({ [name]: value });
    }

    // const getTowns = async () => {
    //     const data = await getTownsAndLgas();
    //     setAddressData(data);
    // }
    const isDisabled = () => {
        return !(address && lga && state);
    }
    // useEffect(() => {
    //     getTowns();
    // }, []);

    useEffect(() => {
    }, [address, state, lga]);

    return (
        <div className="container p-4 pt-2">
            <form name="form" onSubmit={handleSubmit}>
                <fieldset>
                    <div className="row">
                        <div class="mb-3">
                            <label for="inputAddress" class="form-label text-black"><b>Address of the property in Nigeria </b></label>
                            <input type="text" class="form-control" id="inputAddress" autocomplete="off" name="address" value={address} onChange={handleChange} required aria-required="true" />
                        </div>
                        <div class="mb-3">
                            <label for="inputAddress" class="form-label text-black"><b>Street</b></label>
                            <input type="text" class="form-control" id="street" autocomplete="off" name="street" value={street} onChange={handleChange} required aria-required="true" />
                        </div>
                        <div class="mb-3">
                            <label for="inputState" class="form-label text-black"><b>State</b></label>
                            <Typeahead
                                class="form-control"
                                defaultInputValue={state}
                                required
                                name="state"
                                minLength={2}
                                onChange={(selected) => onUpdate({ state: selected[0] })}
                                options={states || []}
                                aria-required="true"
                            />
                        </div>
                        {/* <div class="mb-3">
                            <label for="inputCity" class="form-label text-black"><b>City</b></label>
                            <Typeahead
                                class="form-control"
                                defaultInputValue={city}
                                required
                                name="city"
                                minLength={2}
                                onChange={(selected) => onUpdate({ city: selected[0] })}
                                options={addressData?.towns || []}
                                aria-required="true"
                            />
                        </div> */}
                        <div class="mb-3">
                            <label for="postalcode" class="form-label text-black" ><b>Local Gov Area</b></label>
                            <Typeahead
                                class="form-control"
                                defaultInputValue={lga}
                                required
                                name="lga"
                                minLength={2}
                                onChange={(selected) => onUpdate({ lga: selected[0] })}
                                options={state ? NIGERIAN_STATES.filter(s => s.name === state)[0]?.LGAs : []}
                                aria-required="true"
                            />
                        </div>
                    </div>
                    <div className="row" style={{ position: 'absolute', bottom: '2rem', width: '95%' }}>
                        <div className="col-1">
                            <button class="btn rounded-pill btn-outline-success" type="button" onClick={() => requestType === 'New' ? handleNextPrevClick(active - 1) : handleNextPrevClick(1)}>Back</button>
                        </div>
                        <div className="col-1" style={{ position: 'absolute', right: '5.3rem' }}>
                            <button class="btn btn-success rounded-pill " type="submit" disabled={isDisabled()} >Continue</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div >
    );
};

const Step7 = ({ handleNextPrevClick, active, formValues, onUpdate }) => {
    const [
        selectedValue,
        setSelectedValue,
    ] = useState(formValues?.propertyStatus);

    const handleRadioChange = (e) => {
        const { value } = e.currentTarget;
        setSelectedValue(value);
        onUpdate({ propertyStatus: value });
    }
    return (
        <div className="container p-4 pt-2">
            <form name="form" onSubmit={() => handleNextPrevClick(active + 1)} >
                <fieldset>
                    <div className="row p-2 text-black">
                        <div className="col-12"><b>Do you own this property?</b></div>
                    </div>
                    <div className="row pt-2">
                        <div className="btn-group-vertical">
                            {PROPERTY_STATUS_OPTS.map((propertyStatus, index) => {
                                return <div className="col-12 pb-3 d-grid gap-2">
                                    <input type="radio" className="btn-check" name="btnradio" id={propertyStatus.value} autocomplete="off" value={propertyStatus.label} onChange={handleRadioChange}
                                        checked={selectedValue === propertyStatus.label} required />
                                    <label className="btn btn-outline-success" for={propertyStatus.value}>{propertyStatus.label}</label>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="row" style={{ position: 'absolute', bottom: '2rem', width: '95%' }}>
                        <div className="col-1">
                            <button class="btn rounded-pill btn-outline-success" type="button" onClick={() => handleNextPrevClick(active - 1)}>Back</button>
                        </div>
                        <div className="col-1" style={{ position: 'absolute', right: '5.3rem' }}>
                            <button class="btn btn-success rounded-pill " type="submit">Continue</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );
};

const Step8 = ({ handleNextPrevClick, active, formValues, onUpdate }) => {
    const [
        selectedValue,
        setSelectedValue,
    ] = useState(formValues?.solarInstallPeriod);
    const [
        extraInfo,
        setExtraInfo,
    ] = useState(formValues?.extraInfo);

    const handleRadioChange = (e) => {
        const { value } = e.currentTarget;
        setSelectedValue(value);
        onUpdate({ solarInstallPeriod: value });
    }
    return (
        <div className="container p-4 pt-2">
            <form name="form" onSubmit={() => handleNextPrevClick(active + 1)} >
                <fieldset>
                    <div className="row p-2 text-black">
                        <div className="col-12"><b>When are you considering getting solar installed?</b></div>
                    </div>
                    <div className="row pt-2">
                        <div className="btn-group-vertical">
                            {SOLAR_INSTALL_PERIOD_OPTS.map((solarInstallPeriod) => {
                                return <div className="col-12 pb-3 d-grid gap-2">
                                    <input type="radio" className="btn-check" name="btnradio" id={solarInstallPeriod.value} autocomplete="off" value={solarInstallPeriod.label} onChange={handleRadioChange} checked={selectedValue === solarInstallPeriod.label} required />
                                    <label className="btn btn-outline-success" for={solarInstallPeriod.value}>{solarInstallPeriod.label}</label>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-12">
                            <label for="extraInfo" class="form-label text-black p-2"><b>Additional Information</b></label>
                            <textarea class="form-control" id="extraInfo" name="extraInfo" value={extraInfo} onChange={(e) => { setExtraInfo(e.target.value); onUpdate({ extraInfo: e.target.value }) }} />
                        </div>
                    </div>
                    <div className="row" style={{ position: 'absolute', bottom: '2rem', width: '95%' }}>
                        <div className="col-1">
                            <button class="btn rounded-pill btn-outline-success" type="button" onClick={() => handleNextPrevClick(active - 1)}>Back</button>
                        </div>
                        <div className="col-1" style={{ position: 'absolute', right: '5.3rem' }}>
                            <button class="btn btn-success rounded-pill " type="submit" >Continue</button>
                        </div>
                    </div>
                </fieldset>
            </form>

        </div>
    );
};


const Step10 = ({ handleNextPrevClick, active, formValues, onUpdate, resetForm }) => {
    const { firstName, lastName, email, contactNumber, country, requestingFor } = formValues || {};
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountry();
    }, []);

    const getCountry = async () => {
        const data = await getCountries();
        setCountries(data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        //prepare data to be sent to backend
        const requestData = {
            "customerInfo": {
                "firstName": formValues.firstName,
                "lastName": formValues.lastName,
                "email": formValues.email,
                "contactNumber": formValues.contactNumber,
                "address": formValues.address,
                "state": formValues.state,
                "post": formValues.lga,
                "street": formValues.street,
                "requestedFromCountry": formValues.country,
                "requestingForYourself": formValues.requestingFor
            },
            "requirements": {
                "Type Of Request": formValues.requestType,
                "What type of Solar System can we help you with?": formValues.type,
            }
        };
        if (formValues.capacity) {
            requestData.requirements["Do you know exactly the capacity you need?"] = formValues.capacity;
            if (formValues.apartmentType) {
                requestData.requirements["Apartment Type(If unsure about capacity)"] = formValues.apartmentType;
            }
        } else {
            requestData.requirements["Which option best describes the major appliances you need to manage?"] = formValues.majorAppliances;
        }
        requestData.requirements["What is your property's roof type?"] = formValues.roofType;
        requestData.requirements["Property type for the solar installation"] = formValues.propertyType;
        requestData.requirements["Residential or Commercial?"] = formValues.residentialOrCommercial;
        requestData.requirements["Do you own this property?"] = formValues.propertyStatus;
        requestData.requirements["When are you considering getting solar installed?"] = formValues.solarInstallPeriod;
        requestData.requirements["Are you interested in flexible payment options?"] = formValues.flexiblePayment;
        requestData.requirements["Additional Information"] = formValues.extraInfo;

        //send data to backend
        //  alert('Form Submitted with below data\n\n' + JSON.stringify(requestData, null, 2));
        getQuotation(requestData).then((response) => {
            console.log(response);
        }, (error) => {
            console.log(error);
        });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        onUpdate({ [name]: value });
    }
    return (
        <>
            <div className="container p-4 pt-2">
                <form name="form" onSubmit={handleSubmit}>
                    <fieldset>
                        <div className="d-flex">
                            <div class="mb-3">
                                <label for="inputFirstName" class="form-label text-black"><b>First Name</b></label>
                                <input type="text" class="form-control" id="inputFirstName" name="firstName" value={firstName} onChange={handleChange} required aria-required="true" />
                            </div>
                            <div class="mb-3" style={{ paddingLeft: '0.5rem' }}>
                                <label for="inputLastName" class="form-label text-black"><b>Last Name</b></label>
                                <input type="text" name="lastName" class="form-control" id="inputLastName" value={lastName} onChange={handleChange} required aria-required="true" />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="inputEmail" class="form-label text-black" ><b>Email</b></label>
                            <input type="email" class="form-control" id="inputEmail" name="email" value={email} onChange={handleChange} required aria-required="true" />
                        </div>
                        <div class="mb-3">
                            <label for="inputContact" class="form-label text-black" ><b>Phone Number</b></label>
                            <input type="text" class="form-control" id="inputContact" name="contactNumber" value={contactNumber} onChange={handleChange} required aria-required="true" />
                        </div>
                        <div class="mb-3">
                            <label for="country" class="form-label text-black" ><b>Add Location(Country)</b></label>
                            <Typeahead
                                class="form-control"
                                defaultInputValue={country}
                                required
                                name="country"
                                minLength={2}
                                onChange={(selected) => onUpdate({ country: selected[0] })}
                                options={countries || []}
                                aria-required="true"
                            />
                        </div>
                        <div className="row">
                            <div className="col-6"><b>Requesting For Yourself</b></div>
                            {REQUESTING_FOR_YOURSELF.map((propType) => {
                                return <div class="col-2 form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="requestingFor" id={propType.value} value={propType.label} required
                                        onChange={handleChange} checked={requestingFor === propType.label} />
                                    <label class="form-check-label" for={propType.value}>{propType.label}</label>
                                </div>
                            })}
                        </div>
                        {isSubmitted && <div class="mb-3 mt-5">
                            <span style={{ color: "green" }}><i class="bi bi-check-circle-fill pr-3" style={{ paddingRight: "0.5rem" }}></i><b>Request Submitted Successfully</b></span>
                        </div>}
                        <div className="row" style={{ position: 'absolute', bottom: '2rem', width: '95%' }}>
                            {!isSubmitted && <div className="col-6">
                                <button class="btn rounded-pill btn-outline-success" type="button" onClick={() => handleNextPrevClick(active - 1)}>Back</button>
                            </div>}
                            {!isSubmitted && <div className="col-6" style={{ position: 'absolute', right: '0' }}>
                                <button class="btn btn-success rounded-pill " type="submit" disabled={isSubmitted}>Get My Free Quote</button>
                            </div>}
                            {isSubmitted && <div className="col-12 ">
                                <button class="btn btn-success rounded-pill float-end " type="button" onClick={() => resetForm()}>Reset</button>
                            </div>}
                        </div>
                    </fieldset>
                </form>
            </div>
        </>
    );
};


export default Quotation;
