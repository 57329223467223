import React, { useState } from 'react'
import { contactUS } from '../../services/quotation-service';
function ContactUS({setShow}) {

    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        reason: '',
        message: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        contactUS(formValues).then((response) => {
            console.log(response);
            setFormValues({
                firstName: '',
                lastName: '',
                email: '',
                contactNumber: '',
                reason: '',
                message: '',
            });
            setShow(true);
        }, (error) => {
            console.log(error);
        });
    };
    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({ ...prevState, [name]: value }));
    }
    const isDisabled = () => {
        return !(formValues.firstName && formValues.lastName && formValues.email && formValues.contactNumber && formValues.reason && formValues.message);
    }
    return (
        <div className='modal fade' id='contactus' tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <form name="contact-us-form" onSubmit={(e) => handleSubmit(e)}>
                        <fieldset>
                            <div className="modal-header">
                                <h5 className="modal-title" id="modalLabel">How can we help you?</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row pt-3 g-3'>
                                    <div className='col-6'>
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="yourFirstName" placeholder="Your First Name" name='firstName' value={formValues.firstName} onChange={handleChange} required />
                                            <label for="yourFirstName">Your First Name</label>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="yourLastName" placeholder="Your Last Name" name='lastName' value={formValues.lastName} onChange={handleChange} required />
                                            <label for="yourLastName">Your Last Name</label>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div class="form-floating">
                                            <input type="email" class="form-control" id="yourEmail" placeholder="Your Email" name='email' value={formValues.email} onChange={handleChange} required />
                                            <label for="yourEmail">Your Email</label>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div class="form-floating">
                                            <input type="tel" class="form-control" id="telephoneNumber" placeholder="Telephone Number" name='contactNumber' value={formValues.contactNumber} onChange={handleChange} required />
                                            <label for="telephoneNumber">Telephone Number</label>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div class="form-floating">
                                            <select class="form-select" id="floatingSelect" aria-label="Why you want to contact us?" name='reason' value={formValues.reason} onChange={handleChange} required>
                                                <option value="">Select</option>
                                                <option value="General Enquiries">General Enquiries</option>
                                                <option value="Join as an Installer">Join as an Installer</option>
                                            </select>
                                            <label for="floatingSelect">Why you want to contact us?</label>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div class="form-floating">
                                            <textarea class="form-control" id="yourMessage" placeholder="Your Message" name='message' value={formValues.message} onChange={handleChange} required></textarea>
                                            <label for="yourMessage">Your Message</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button class="btn btn-success" type="submit" data-bs-dismiss="modal" disabled={isDisabled()}>Submit</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactUS;