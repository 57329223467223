import React, { useState } from 'react';
import './refer.css';
import referFriend from '../../assets/refer-friend.jpg';
import { SOLAR_OPTS } from '../quotation/constants';
import { referAFriend } from "../../services/quotation-service";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function Refer({ setShow }) {
	const [formValues, setFormValues] = useState({});

	const handleSubmit = (e) => {
		e.preventDefault();
		// prepare data to be sent to backend
		const requestData = {
			"referrer": {
				"firstName": formValues.firstName,
				"lastName": formValues.lastName,
				"email": formValues.email,
				"phoneNumber": formValues.phoneNumber
			},
			// "referee": {
			// 	"firstName": formValues.friendFirstName,
			// 	"lastName": formValues.friendLastName,
			// 	"email": formValues.friendEmail,
			// 	"telephoneNumber": formValues.friendTelephoneNumber,
			// 	"solarSystemType": formValues.solarSystemType,
			// 	"shareFriendDetails": formValues.shareFriendDetails ? 'Yes' : 'No'
			// }
		};
		referAFriend(requestData).then((response) => {
			console.log(response);
			setFormValues({
				firstName: '',
				lastName: '',
				email: '',
				phoneNumber: '',
				// friendFirstName: '',
				// friendLastName: '',
				// friendEmail: '',
				// friendTelephoneNumber: '',
				// solarSystemType: '',
				shareFriendDetails: false
			});
			setShow(true);
		}, (error) => {
			console.log(error);
		});
	};
	const handleChange = async (e) => {
		const { name, value } = e.target;
		setFormValues(prevState => {
			if (name === 'shareFriendDetails') {
				return { ...prevState, [name]: e.target.checked }
			}
			return { ...prevState, [name]: value }
		});
	}
	return (
		<>
			<div className='container scroll-margin-top-150' id='refer'>
				<div className='row'>
					<div className='col-sm-6 col-xs-12 p-3 align-items-stretch' >
						<h2>Earn N50,000 and give away N50,000 off!</h2><br />
						<div className='card'>
							<div className='card-body' style={{ backgroundColor: 'rgb(192, 255, 0)' }}>
								<div>
									<span><b>Spread solar at a sunny price</b></span>
								</div>
								{/* <div className='pt-3'>
									<span className="badge rounded-pill bg-success">1</span>
									<span className='mx-2'>Enter your friend's contact details in the form so we can contact them.</span><span><b>You can nominate as many friends as you like.</b></span>
								</div> */}
								<div className='pt-3'>
									{/* <span className="badge rounded-pill bg-success">2</span> */}
									<span className='mx-2'>Earn</span><span><b>N50,000 for every friend </b></span><span>that installs solar with SolarEaseHub, with no limits. Your friend will also receive a </span><span><b>N50,000 discount.</b></span>
								</div>
							</div>
						</div>
						<div className='pt-4'><img src={referFriend} alt="" style={{ width: '100%' }} /></div>
					</div>
					<div className='col-sm-6 col-xs-12 p-3 align-items-stretch' >
						<form name="refer-friend-form" onSubmit={(e) => handleSubmit(e)}>
							<fieldset>
								<div id="refer-a-friend" className="quota-box rounded-3 h-100">
									<div className="p-4">
										<div className='refer-friend-h3 mb-2'><b>Free and no-obligation</b></div>
										<h2><b>Be a SolarEase Agent</b></h2>
										<div className='row pt-3 g-3'>
											<div className='col-6'>
												<div class="form-floating">
													<input type="text" class="form-control" id="yourFirstName" placeholder="Your First Name" name='firstName' value={formValues.firstName} onChange={handleChange} required />
													<label for="yourFirstName"><b>Your First Name</b></label>
												</div>
											</div>
											<div className='col-6'>
												<div class="form-floating">
													<input type="text" class="form-control" id="yourLastName" placeholder="Your Last Name" name='lastName' value={formValues.lastName} onChange={handleChange} required />
													<label for="yourLastName"><b>Your Last Name</b></label>
												</div>
											</div>
											<div className='col-12'>
												<div class="form-floating">
													<input type="email" class="form-control" id="yourEmail" placeholder="Your Email" name='email' value={formValues.email} onChange={handleChange} required />
													<label for="yourEmail"><b>Your Email</b></label>
												</div>
											</div>
											<div className='col-12'>
												<div class="form-floating">
													<input type="text" class="form-control" id="yourPhoneNumber" placeholder="Your Email" name='phoneNumber' value={formValues.phoneNumber} onChange={handleChange} required />
													<label for="yourPhoneNumber"><b>Your Phone Number</b></label>
												</div>
											</div>
											<div className='col-12'>
												<div class="form-check form-control" style={{ paddingLeft: '2.5rem' }}>
													<input class="form-check-input" type="checkbox" value={formValues.shareFriendDetails} checked={formValues.shareFriendDetails} id="shareFriendDetailsCheckbox" name='shareFriendDetails' onChange={handleChange} required />
													<label class="form-check-label" for="shareFriendDetailsCheckbox">
														I allow SolarEase executive to contact me.
													</label>
												</div>
											</div>
										</div>

										{/* <h3 className='pt-3'>Friends Details</h3>
										<div className='row pt-3 g-3'>
											<div className='col-6'>
												<div class="form-floating">
													<input type="text" class="form-control" id="firstName" placeholder="First Name" name='friendFirstName' value={formValues.friendFirstName} onChange={handleChange} required />
													<label for="firstName"><b>First Name</b></label>
												</div>
											</div>
											<div className='col-6'>
												<div class="form-floating">
													<input type="text" class="form-control" id="lastName" placeholder="Last Name" name='friendLastName' value={formValues.friendLastName} onChange={handleChange} required />
													<label for="lastName"><b>Last Name</b></label>
												</div>
											</div>
											<div className='col-12'>
												<div class="form-floating">
													<input type="email" class="form-control" id="friendEmail" placeholder="Email Address" name='friendEmail' value={formValues.friendEmail} onChange={handleChange} required />
													<label for="email"><b>Email Address</b></label>
												</div>
											</div>
											<div className='col-12'>
												<div class="form-floating">
													<input type="tel" class="form-control" id="telephoneNumber" placeholder="Telephone Number" name='friendTelephoneNumber' value={formValues.friendTelephoneNumber} onChange={handleChange} required />
													<label for="telephoneNumber"><b>Telephone Number</b></label>
												</div>
											</div>
											<div className='col-12'>
												<div class="form-floating">
													<select class="form-select" id="floatingSelect" aria-label="What type of solar system?" name='solarSystemType' value={formValues.solarSystemType} onChange={handleChange} required>
													<option value="">Select</option>
														{SOLAR_OPTS.map((opt) => <option value={opt.label}>{opt.label}</option>)}
													</select>
													<label for="floatingSelect"><b>What type of solar system?</b></label>
												</div>
											</div>
											<div className='col-12'>
												<div class="form-check form-control" style={{ paddingLeft: '2.5rem' }}>
													<input class="form-check-input" type="checkbox" value={formValues.shareFriendDetails} checked={formValues.shareFriendDetails} id="shareFriendDetailsCheckbox" name='shareFriendDetails' onChange={handleChange} required />
													<label class="form-check-label" for="shareFriendDetailsCheckbox">
														I have permission to share my friend's details
													</label>
												</div>
											</div>
										</div> */}
										<div className='row pt-3 justify-content-center pb-4'>
											<div className='col-4' >
												<button class="btn btn-success rounded-pill w-100" type="submit" >Submit</button>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</div>

		</>
	)
}

export default Refer