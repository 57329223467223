import React, { useEffect, useState } from "react";
import './App.css';
import Header from './components/header/header';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Landing from "./components/landing-page/landing";
import Reviews from "./components/reviews/reviews";
import Information from "./components/information/info";
import HowItWorks from "./components/how/how-it-works";
import Footer from "./components/footer/footer";
import About from "./components/about/about";
import Refer from "./components/refer/refer";
import ContactUS from "./components/contact-us/contact-us";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { testApi } from "./services/quotation-service";

function App() {
  const [show, setShow] = useState(false);
	useEffect(() => {
		testApi().then((res) => {
			console.log('Service is Up and Running');
		})
	},[]);
  return (
    <>
      <Header />
      <div
				aria-live="polite"
				aria-atomic="true"
				className="position-relative"
			>
				<ToastContainer
					className='text-success font-weight-bold bg-opacity-75 '
					position='top-end'
					style={{ zIndex: 1100, position:'fixed', marginTop: '100px'}}
				>
					<Toast onClose={() => setShow(false)} show={show} delay={3000} autohide >
						<Toast.Header>
							<strong className="me-auto text-success font-weight-bold">Request Submitted Successfully</strong>
						</Toast.Header>
						<Toast.Body>Your Request has been submitted.</Toast.Body>
					</Toast>
				</ToastContainer>
        </div>
      <Landing />
      <Reviews />
      <Information />
      <HowItWorks />
      <Refer setShow={setShow}/>
      <About />
      <ContactUS setShow={setShow}/>
      <Footer />
    </>
  );
}

export default App;
