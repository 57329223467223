import axios from "axios";

export const testApi = () => {
    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/test`
        )
};

export const getQuotation = (requestData) => {
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/quotation-request`,
            { ...requestData }
        )
};

export const referAFriend = (requestData) => {
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/refer-friend`,
            { ...requestData }
        )
};

export const contactUS = (requestData) => {
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/contact-us`,
            { ...requestData }
        )
};