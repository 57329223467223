import React from "react";

export default function Reviews() {
	const reviews = [
		{
			name: '',
			title: 'Really Insightful, Cost-Effective And Trustworthy',
			comment: "I'm so glad I chose SolarEase to install my solar panel system. The team was professional, efficient, and knowledgeable. They walked me through the entire process and answered all my questions. My electricity bill has been significantly reduced, and I'm now generating my own clean energy. I highly recommend SolarEase!"
		},
		{
			name: '',
			title: 'Nice Easy Straightforward Process',
			comment: "I was initially hesitant about switching to solar, but the consultant made the process easy and hassle-free. The installation was completed on time and within budget. I've been enjoying the benefits of solar energy for months now and couldn't be happier with my decision."
		}, {
			name: '',
			title: 'Really Insightful, Cost-Effective And Trustworthy',
			comment: "I was impressed with the quality of the solar panels and the professionalism of the installation team. They took the time to explain everything to me and ensure that I was satisfied with the results. My system has been working flawlessly, and I've seen a noticeable reduction in my energy costs."
		},
		{
			name: '',
			title: 'Nice Easy Straightforward Process',
			comment: "Solar Ease went above and beyond to meet my needs. They customized a solar panel system that was perfect for my home and my budget for my grandmother in the village. The installation was clean and efficient, and the team was always available to answer my questions. I'm so grateful for their excellent service."
		}, {
			name: '',
			title: 'Really Insightful, Cost-Effective And Trustworthy',
			comment: "I've been recommending SolaeEse to all my friends in USA and family. They provided me with a top-notch solar panel system and outstanding customer service. I'm not only saving money on my electricity bill but also contributing to a cleaner planet. I couldn't be more satisfied!"
		}
	]
	return (
		<>
			<div className="container" id="reviews" style={{ paddingTop: '50px', scrollMarginTop: '100px' }}>
				<div className="d-flex flex-row flex-nowrap overflow-auto">
					{
						reviews.map((review, index) => {
							return (
								<div className="card card-block mt-3 mx-2" key={index} style={{ minWidth: '300px' }}>
									<div className="card-body">
										<img src="//d9hhrg4mnvzow.cloudfront.net/get.soly-energy.co.uk/welcome/aefef533-full-stars.svg" alt="" style={{ width: '173px', height: '32px' }} />
										<div className="pt-2 text-black">
											{review.name &&<span style={{ fontWeight: "500", fontSize: "18px", fontStyle: "italic" }}>
												{review.name}
											</span>}
										</div>
										<div className="pt-2 text-black">
											<span style={{ fontWeight: "400", fontStyle: "italic" }}>
												{review.comment}
											</span>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
			<div className="container">
				<div className="row mt-5">
					<div className="col text-center">
						<img src="logo.png" alt="" style={{ width: '300px' }} className="px-4" />
						<p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Certified</p>
					</div>
				</div>
			</div >
		</>
	);
}