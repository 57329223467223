import React from 'react'
import save from '../../assets/save.svg';
import solarPanel from '../../assets/solar-panel.svg';
import panel from '../../assets/panel.svg';
import GetQuoteButton from '../get-my-quotes';
import completeSystem from '../../assets/landing-bg-3.jpg';
import graph from '../../assets/graph.svg';
import microInverter from '../../assets/micro-inverter.svg';
import homeBattery from '../../assets/home-battery.svg';
import solarCctv from '../../assets/solar/solar-cctv.jpg';
import solarPanelNew from '../../assets/solar/solar-panel.jpg';
import solarStreetLight from '../../assets/solar/solar-street-light.jpg';
import solarWaterPump from '../../assets/solar/solar-water-pump.jpg';
import solarInverter from '../../assets/solar/solar-inverter.jpg';

const sliderData = [
	{
		image: solarPanelNew,
		title: 'Solar Panel'
	},
	{
		image: solarCctv,
		title: 'Solar CCTV'
	},
	{
		image: solarStreetLight,
		title: 'Solar Street Light'
	},
	{
		image: solarWaterPump,
		title: 'Solar Water Pump'
	},
	{
		image: solarInverter,
		title: 'Solar Something'
	}
]


function Information() {
	return (
		<div className='container'>
			<div className='row mt-5'>
				<div className='col'>
					<div className='text-center py-5' style={{ backgroundColor: '#eee' }}>
						{/* <div className='landing-title theme-text'>WHY PAY MORE FOR SOLAR</div> */}
						<div style={{ fontWeight: 700, fontSize: '1.8rem', color: 'rgb(0, 0, 0)', fontStyle: 'normal' }}>Is solar a smart investment for your energy future?</div>
						<div className='container'>
							<div className='row mx-sm-5'>
								<div className='col-sm-4 col-xs-12 p-3 align-items-stretch' >
									<div className='card h-100'>
										<div className='card-body'>
											<img src={save} alt="" style={{ width: '64px' }} />
											<div style={{ fontWeight: 600, fontSize: '20px', color: 'rgb(0, 0, 0)', fontStyle: 'normal' }}>Start lowering your bills within 8 weeks with a fast and easy installation.</div>
										</div>
									</div>
								</div>
								<div className='col-sm-4 col-xs-12 p-3 align-items-stretch' >
									<div className='card h-100'>
										<div className='card-body'>
											<img src={panel} alt="" style={{ width: '64px' }} />
											<div style={{ fontWeight: 600, fontSize: '20px', color: 'rgb(0, 0, 0)', fontStyle: 'normal' }}>Unlock N1,000,000 in annual savings on your energy costs. </div>
										</div>
									</div>
								</div>
								<div className='col-sm-4 col-xs-12 p-3 align-items-stretch' >
									<div className='card h-100'>
										<div className='card-body'>
											<img src={graph} alt="" style={{ width: '64px' }} />
											<div style={{ fontWeight: 600, fontSize: '20px', color: 'rgb(0, 0, 0)', fontStyle: 'normal' }}>See payback in just 7-8 years, nearly half the industry average of 14 years! </div>
										</div>
									</div>
								</div>
							</div>
							<div className='row my-4'>
								<div className='col text-center'>
									<GetQuoteButton />
								</div>
							</div>
						</div>
					</div>
					<div className='container pt-5'>
						<div className='row mt-5'>
							<div className='col-12' >
								<p className='landing-title theme-text'>TAKE BACK YOUR INDEPENDENCE</p>
								<p style={{ lineHeight: '52px', fontWeight: 700, fontSize: '1.8rem', color: 'rgb(0, 0, 0)', fontStyle: 'normal' }}>Tired of Rising Electricity Costs? Take Control with Solar Power!</p>
								<p style={{ lineHeight: '29px', fontWeight: 400, fontSize: '1.3rem', color: 'rgb(68, 68, 68)', fontStyle: 'normal' }}>Electricity prices are on the rise in Nigeria, just like many places around the world.
									According to a recent survey, many Nigerians are finding it difficult to afford their electricity bills.
									<br />
									<br /><strong>But there's a solution!</strong> Switch to solar power and <strong>start saving money on your electricity bills today</strong>
									With solar, you'll be generating your own clean energy, reducing your reliance on the grid and protecting yourself from future price increases.
									<br /><br />We can help you make the switch to solar power easily and affordably. Get in touch today to learn more!</p>
								<div className='row my-5 text-center'>
									<div className='col'>
										<GetQuoteButton />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='container py-5 mt-5' style={{ backgroundColor: '#eee' }}>
						<div className='row'>
							<div className='col pl-5'>
								<p style={{ lineHeight: '52px', fontWeight: 700, fontSize: '1.8rem', color: 'rgb(0, 0, 0)', fontStyle: 'normal' }}>Get The Complete System Without Draining Your Savings</p>
							</div>
						</div>
						<div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
							<div className="carousel-indicators">
								{
									sliderData.map((item, index) => {
										return (
											<button
												key={index}
												type="button"
												data-bs-target="#carouselExampleDark"
												data-bs-slide-to={index}
												aria-current="true"
												aria-label={`Slide ${index + 1}`}
												className={index === 0 ? 'active' : ''}
											>
											</button>
										)
									})
								}
							</div>
							<div className="carousel-inner">
									{
										sliderData.map((item, index) => {
											return (
												<div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} data-bs-interval="2000">
													<img src={item.image} className="d-xs-block d-sm-none w-100 m-auto" alt="..." />
													<img src={item.image} className="d-none d-sm-block w-50 m-auto" alt="..." />
													<div className="carousel-caption d-sm-block">
														<h5 className='text-white'>{item.title}</h5>
													</div>
												</div>
											)
										})
									}
							</div>
							<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
								<span className="carousel-control-prev-icon" aria-hidden="true"></span>
								<span className="visually-hidden">Previous</span>
							</button>
							<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
								<span className="carousel-control-next-icon" aria-hidden="true"></span>
								<span className="visually-hidden">Next</span>
							</button>
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default Information