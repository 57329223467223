import axios from "axios";

export const getTownsAndLgas = async () => {
    const states = await axios.get(`https://nigeria-states-towns-lgas.onrender.com/api/all`);
    const respone = {
        towns: [],
        lgas: [],
        states: [],
    }
    for (let state of states.data) {
        respone.states.push(state.name);
        state.towns.forEach((town) => {
            respone.towns.push(town.name);
        })
        state.lgas.forEach((lga) => {
            respone.lgas.push(lga.name);
        })
    }
    respone.towns = respone.towns.sort();
    respone.lgas = respone.lgas.sort();
    return respone;
};

export const getCountries = async () => {
    const countries = await axios.get(`https://restcountries.com/v3.1/all?fields=name`);
    if (countries.data.length > 0) {
        return countries.data.map((country) => country.name.common);
    }
    return [];
};
