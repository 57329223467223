import React from 'react'
import getQuote from '../../assets/get-quote.jpg';
import GetQuoteButton from '../get-my-quotes';
function HowItWorks() {
	return (
		<div className='container scroll-margin-top-150' id='howItWorks'>
			<div className='row mt-5'>
				<div className='col-sm-6 col-xs-12 text-end'>
					<img src={getQuote} alt="" style={{ width: '100%', margin: 'auto' }} />
				</div>
				<div className='col-sm-6 col-xs-12 p-4 pt-0'>
					<p style={{ lineHeight: '52px', fontWeight: 700, fontSize: '1.8rem', color: 'rgb(0, 0, 0)', fontStyle: 'normal' }}>Get Your Complimentary Quote In Minutes </p>
					<p style={{ lineHeight: '34px', fontWeight: 400, fontSize: '1.3rem', color: 'rgb(68, 68, 68)', fontStyle: 'normal' }}>1) Complete Our Short Questionnaire&nbsp;</p>
					<p style={{ lineHeight: '34px', fontWeight: 400, fontSize: '1.3rem', color: 'rgb(68, 68, 68)', fontStyle: 'normal' }}>2) We Analyse the Information and Give you a quote</p>
					<p style={{ lineHeight: '34px', fontWeight: 400, fontSize: '1.3rem', color: 'rgb(68, 68, 68)', fontStyle: 'normal' }}>3) Upon acceptance, you will have an opportunity to Speak To One Of Our Solar Consultants</p>
					<p style={{ lineHeight: '34px', fontWeight: 400, fontSize: '1.3rem', color: 'rgb(68, 68, 68)', fontStyle: 'normal' }}>4) You’ll also receive:
						<ul style={{ lineHeight: '34px', fontWeight: 400, fontSize: '1.1rem', color: 'rgb(68, 68, 68)', fontStyle: 'normal', marginLeft: '20px' }}>
							<li>Everything you need to know about solar</li>
							<li>A complete renewable survey of your home (optional)</li>
						</ul>
					</p>
					<p style={{ lineHeight: '34px', fontWeight: 400, fontSize: '1.3rem', color: 'rgb(68, 68, 68)', fontStyle: 'normal' }}>5) Get the Best Offer Available on the Market and Installed by an approved installer!</p>

					<div className='row my-5'>
						<div className='col'>
							<GetQuoteButton />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HowItWorks